<template>
    <div
      class="fixed z-30 top-0 left-0 w-full h-screen inline-flex items-center"
    >
      <div
        class="popup-container overflow-hidden absolute z-100 right-1/4 top-1/2
              -translate-y-1/2 transform w-96 bg-white text-black"
      >
        <div class="popup-content w-full h-full p-4 xl:p-6">
          <t-button
            variant="circle"
            class="mb-3"
            @click="hide"
          >
            <i class="icon-close" />
          </t-button>

          <h3 class="text-base font-medium leading-normal text-orange-500 mb-3">
            {{ product.info.title }}
          </h3>

          <div
            v-html="product.info.body"
            class="max-h-20vh pr-4 overflow-auto custom-scrollbar
                  text-base 2xl:text-lg font-medium leading-tight mb-6 2xl:mb-8"
          />

          <div class="flex items-center justify-between gap-x-4">
            <t-button @click="explore">
              Explore
            </t-button>

            <t-button
              v-if="!isSelected"
              variant="circle"
              @click="addToCollection"
            >
              <i class="icon-like" />
            </t-button>

            <t-button
              v-else
              variant="circle-reversed"
              @click="removeFromCollection"
            >
              <i class="icon-like" />
            </t-button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';

export default {
  name: 'SolutionDemoPopup',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selected,
    ]),
    isSelected() {
      return this.medias
        .map((m) => this.selected.some((sel) => sel.uid === m.uid))
        .every((ch) => ch === true);
    },
    medias() {
      return this.product.slides
        .map((s) => s.media)
        .filter((m) => m);
    },
  },
  methods: {
    ...mapActions([
      actions.contactForm.select,
      actions.contactForm.removeItem,
    ]),
    hide() {
      this.$emit('close');
    },
    explore() {
      this.$emit('explore');
    },
    addToCollection() {
      this[actions.contactForm.select](this.medias);
    },
    removeFromCollection() {
      this[actions.contactForm.removeItem](this.medias);
    },
  },
};
</script>
