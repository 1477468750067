<template>
  <div class="slide-content p-6 2xl:p-8">
    <h3 class="text-2xl 2xl:text-3xl font-semibold leading-snug mb-4 2xl:mb-8">
      {{ title }}
      <span class="block text-orange-500">
        {{ slide.title }}
      </span>
    </h3>

    <div
      class="max-h-37vh 2xl:max-h-37vh pr-4 overflow-auto custom-scrollbar
             text-block text-base font-medium leading-normal"
    >
      <div v-html="slide.body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideComponent',
  props: {
    title: {
      type: String,
      require: true,
    },
    slide: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';

.slide-content {
  p {
    &:not(:last-child) {
      margin-bottom: 1.3rem;
    }
  }

  ol {
    list-style: none;

    > li {
      position: relative;
      padding: 1rem 0.75rem 1rem 1.5rem;
      background-color: #F3F4F6;
      font-size: 1.125rem;
      font-weight: 600;
      color: $color-black;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        left: 0.75rem;
        width: 0;
        border-left: 1px solid $color-brand;
      }
    }
  }
}
</style>
