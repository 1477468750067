import { VueOfflineStorage } from 'vue-offline';

const mutations = {
  showNavigation: 'SHOW_NAVIGATION',
  showMyCollectionOverlay: 'SHOW_MY_COLLECTION_OVERLAY',
};

const actions = {
  showNavigation: 'SHOW_NAVIGATION',
  showMyCollectionOverlay: 'SHOW_MY_COLLECTION_OVERLAY',
};

const getters = {
  showNavigation: 'showNavigation',
  isMyCollectionShown: 'isMyCollectionShown',
};

const cacheKey = 'isMyCollectionShown';

const addToCache = async (data) => {
  VueOfflineStorage.set(cacheKey, data);
};

const cached = VueOfflineStorage.get(cacheKey);

const module = {
  state: {
    showNavigation: false,
    isMyCollectionShown: cached || false,
  },
  mutations: {
    [mutations.showNavigation](state, showNavigation) {
      state.showNavigation = showNavigation;
    },
    [mutations.showMyCollectionOverlay](state, isMyCollectionShown) {
      state.isMyCollectionShown = isMyCollectionShown;
    },
  },
  actions: {
    [actions.showNavigation]({ commit }, showNavigation) {
      commit(mutations.showNavigation, showNavigation);
    },
    async [actions.showMyCollectionOverlay]({ commit, state }, isMyCollectionShown) {
      commit(mutations.showMyCollectionOverlay, isMyCollectionShown);
      await addToCache(state.isMyCollectionShown);
    },
  },
  getters: {
    [getters.showNavigation](state) {
      return state.showNavigation;
    },
    [getters.isMyCollectionShown](state) {
      return state.isMyCollectionShown;
    },
  },
};

export default {
  module,
  actions,
  getters,
};
