<template>
  <div
    id="app"
    class="h-screen overflow-hidden"
  >

  <loader v-if="loading" />

  <template v-else>
    <router-view />

    <app-footer v-if="!$route.meta.hideFooter" />
    <keyboard />
  </template>
  </div>
</template>

<script>
import AppFooter from '@/layouts/default/footer.vue';
import { Loader, Keyboard } from '@/components';
import { mapActions } from 'vuex';
import { actions } from '@/store';
import manifestUtil from '@/utils/manifest-util';

export default {
  metaInfo() {
    return {
      title: this.$route.meta.title,
      titleTemplate: '%s | DMI',
    };
  },
  components: {
    Loader,
    AppFooter,
    Keyboard,
  },
  data() {
    return {
      loading: true,
      experienceUid: '',
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.experienceUid = val.params.experienceUid;

        if (this.experienceUid) {
          this[actions.content.loadContent](this.experienceUid)
            .then(() => {
              this.loading = false;
            });
        }
      },
      immediate: true,
    },
    experienceUid: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          manifestUtil.setupManifest(newVal);
        }
      },
    },
  },
  mounted() {
    setInterval(() => {
      this[actions.content.loadContent](this.experienceUid);
    }, process.env.VUE_APP_API_FETCH_INTERVAL);
  },
  methods: {
    ...mapActions([
      actions.content.loadContent,
    ]),
  },
};
</script>

<style lang="scss" src="@/assets/scss/app.scss"></style>
