<template>
  <div ref="wrapper">
    <canvas
      :id="_uid"
      style="width: 100%;"
    />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import * as pdfjsLib from 'pdfjs-dist/build/pdf.min';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export default {
  name: 'PDFPreview',
  props: {
    fileUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.renderThumbnails();
  },
  methods: {
    async renderThumbnails() {
      const worker = new pdfjsLib.PDFWorker();

      const pdf = await pdfjsLib
        .getDocument({
          url: this.fileUrl,
          worker,
        })
        .promise.then((pdfDoc) => pdfDoc);

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 3.0 });

      const canvas = document.getElementById(this._uid);
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const context = canvas.getContext('2d');

      await page.render({
        canvasContext: context,
        viewport,
      });
    },
  },
};
</script>
