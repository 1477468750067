<template>
  <transition name="fade-in">
    <section
      v-if="showOverlay"
      class="splash-screen fixed z-150 left-0 top-0 w-full h-screen
             flex flex-col justify-end py-16 cursor-pointer"
      @click="hide"
    >
      <video
        ref="video"
        :src="screensaver.video"
        :poster="poster"
        class="absolute top-0 left-0 w-full h-full object-cover"
        autoplay
        muted
        loop
      />

      <div
        class="absolute top-0 left-0 w-full h-full bg-black-500"
      />

      <img
        :src="identityLogo"
        alt=""
        class="absolute z-10 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />

      <div class="relative z-10 container px-8">
        <div class="text-center">
          <t-button
            variant="highlight"
          >
            {{ screensaver.button }}
          </t-button>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import poster from '@/assets/images/home/poster.jpg';
import { mapGetters } from 'vuex';
import { getters } from '@/store';

export default {
  name: 'SplashScreen',
  data() {
    return {
      poster,
      showOverlay: false,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.screensaver,
      getters.content.identity,
    ]),
    identityLogo() {
      return this.identity.logo;
    },
  },
  methods: {
    show() {
      this.showOverlay = true;
    },
    hide() {
      this.showOverlay = false;
    },
  },
};
</script>
