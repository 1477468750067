import { isValidNumber } from 'libphonenumber-js';

export default {
  name: 'phone',
  schema: {
    validate(value) {
      return { valid: isValidNumber(`+${value}`), data: { value } };
    },
    message: 'Please specify a valid phone number',
  },
};
