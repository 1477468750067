import { showcase } from '@/constants/route-names';

const component = () => import('./showcase.view.vue');

export default [
  {
    path: 'showcase',
    name: showcase.index,
    component,
    meta: {
      title: 'Showcase',
      breadcrumbs: [
        {
          text: 'Showcase',
          to: { name: 'showcase.index' },
          active: true,
        },
      ],
    },
  },
];
