export default {
  image: {
    key: 'image',
    name: 'Image',
  },
  video: {
    key: 'video',
    name: 'Video',
  },
  document: {
    key: 'document',
    name: 'Document',
  },
};
