import { VueOfflineStorage } from 'vue-offline';
import { auth as authApi } from '@/services/api';

const mutations = {
  loadContent: 'LOAD_CONTENT',
};

const actions = {
  loadContent: 'LOAD_CONTENT',
};

const getters = {
  experienceUid: 'experienceUid',
  identity: 'identity',
  products: 'products',
  screensaver: 'screensaver',
  solutions: 'solutions',
  scene: 'scene',
  showcase: 'showcase',
};

let cacheKey;

const addToCache = async (data) => {
  cacheKey = `content-${data.uid}`;
  VueOfflineStorage.set(cacheKey, data);
};

const getContent = async (experienceUid) => {
  const response = await fetch(`${process.env.VUE_APP_API_CONTENT_PATH}/${experienceUid}`, {
    method: 'GET',
    headers: authApi.getAuthHeader(),
  });
  return response.json();
};

const cachedContent = VueOfflineStorage.get(cacheKey);

const module = {
  state: {
    content: cachedContent || {},
  },
  getters: {
    [getters.experienceUid](state) {
      return state.content.uid;
    },
    [getters.identity](state) {
      return state.content.identity;
    },
    [getters.screensaver](state) {
      return state.content.screensaver;
    },
    [getters.solutions](state) {
      return state.content.solutions;
    },
    [getters.scene](state) {
      return state.content.scene;
    },
    [getters.showcase](state) {
      return state.content.showcase;
    },
  },
  mutations: {
    [mutations.loadContent](state, content) {
      state.content = content;
    },
  },
  actions: {
    async [actions.loadContent]({ commit }, experienceUid) {
      try {
        const data = await getContent(experienceUid);
        commit(mutations.loadContent, data);
        await addToCache(data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default {
  module,
  actions,
  getters,
};
