import Vue from 'vue';
import VueRouter from 'vue-router';

import initialComponent from '@/views/initial/initial.view.vue';
import { routes as factoryRoutes } from '@/views/factory';
import { routes as showcaseRoutes } from '@/views/showcase';
import { routes as notFoundRoutes } from '@/views/not-found';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:experienceUid',
    component: initialComponent,
    children: [
      ...factoryRoutes,
      ...showcaseRoutes,
      ...notFoundRoutes,
    ],
  },
  {
    path: '/',
    redirect: () => '/9j85wqko'
    ,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE_URL,
});

export default router;
