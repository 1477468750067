<template>
  <vue-tel-input
    v-model="phone"
    :inputOptions="{
      showDialCode: true,
      placeholder,
    }"
    class="appearance-none rounded w-full px-2 py-1 border text-black
         hover:border-black focus:outline-none focus:border-black
         border-gray bg-white placeholder-gray-500 text-base 2xl:text-xl transition-colors"
    @input="onInput"
    @focus="onFocus"
    @click.stop
  />
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'PhoneInput',
  components: { VueTelInput },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone: '',
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!val) return;
        this.phone = `+${val}`;
      },
      immediate: true,
    },
  },
  methods: {
    onInput(value, phoneObject) {
      if (!phoneObject?.countryCallingCode && !phoneObject?.nationalNumber) {
        this.$emit('input', value.replace(/[^0-9]/g, ''));
      } else {
        this.$emit('input', `${phoneObject.countryCallingCode}${phoneObject.nationalNumber}`);
      }
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
