<template>
  <button
    class="group inline-flex items-center gap-x-2 cursor-pointer absolute select-none"
    :class="{ 'flex-row-reverse' : reversed }"
    :style="computedStyle"
    @click="onClick"
  >
    <span
      ref="hotspotDot"
      class="relative border border-white rounded-full w-10 2xl:w-12 h-10 2xl:h-12
           bg-black-900 text-white transition-colors group-hover:bg-orange-600"
    >
      <i class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 icon-plus" />
    </span>

    <span
      class="hotspot__label border border-white rounded  px-3 py-2
           bg-black-900 text-white text-sm 2xl:text-base font-normal
             text-left
             transition-colors
           group-hover:bg-orange-600"
    >
    {{ hotspot.title }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'Hotspot',
  props: {
    reversed: {
      type: Boolean,
      default: false,
    },
    activeImageIndex: {
      type: Number,
      require: true,
    },
    hotspot: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    containerHeight: {
      type: Number,
      require: true,
    },
    containerWidth: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      hotspotCoords: null,
    };
  },
  computed: {
    hotspotPositions() {
      return this.hotspotCoords.reduce((result, hotspotPosition) => {
        // eslint-disable-next-line no-param-reassign
        result[hotspotPosition.imageIndex] = hotspotPosition;
        return result;
      }, {});
    },
    computedStyle() {
      if (!this.hotspotCoords) return {};
      const currentPosition = this.hotspotPositions[this.activeImageIndex];

      if (currentPosition) {
        return this.updateHotspotIconPosition(currentPosition.xCoord, currentPosition.yCoord);
      }
      return { visibility: 'hidden', opacity: 0 };
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    fillEmptyCoordWithPrevious(positions, hotspotIndex, coord) {
      const coordIndexes = new Array(hotspotIndex);

      const initialValue = '0%';

      for (let i = coordIndexes.length - 1; i > -1; i -= 1) {
        const previousXCoord = positions[i]?.[coord];

        if (previousXCoord) {
          return previousXCoord;
        }
      }

      return initialValue;
    },
    prepareHotspotsPositions(positions) {
      return positions.reduce((accumulate, current, currentIndex) => {
        const isIncludesXcoord = !!current?.xCoord;
        const isIncludesYcoord = !!current?.yCoord;

        const coords = current;

        if (!isIncludesXcoord) {
          coords.xCoord = this.fillEmptyCoordWithPrevious(positions, currentIndex, 'xCoord');
        }

        if (!isIncludesYcoord) {
          coords.yCoord = this.fillEmptyCoordWithPrevious(positions, currentIndex, 'yCoord');
        }

        accumulate.push(coords);

        return accumulate;
      }, []);
    },
    updateHotspotIconPosition(xCoord, yCoord) {
      const translateX = `${xCoord * this.containerWidth}px`;
      const translateY = `${(yCoord * this.containerHeight)}px`;

      const style = {
        visibility: 'visible',
        opacity: 1,
        zIndex: 20,
        left: `${-this.$refs.hotspotDot.offsetWidth / 2}px`,
        bottom: `${this.containerHeight - (this.$refs.hotspotDot.offsetHeight / 2)}px`,
        transform: `translate3d(${translateX}, ${translateY}, 0)`,
      };
      return style;
    },
  },
  mounted() {
    this.hotspotCoords = this.prepareHotspotsPositions(this.hotspot.positions);
  },
};
</script>

<style lang="scss">
.hotspot__label {
  max-width: 435px;

  @media (max-width: 1024px) {
    max-width: 235px;
  }
}
</style>
