<template>
  <div
    class="fixed z-30 top-0 left-0 w-full h-screen py-16 text-black "
  >
    <div
      class="overlay absolute top-1/2 left-1/2
             transform -translate-x-1/2 -translate-y-1/2 bg-black-850"
      style="width: 105vw; height: 105vh;"
    />
    <section class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                    ">
      <div class="container px-8 transform -translate-y-10">
        <div class="relative z-100 max-w-2xl mx-auto">
          <t-button
            class="close-button mb-1"
            @click="hide"
          >
            <i class="icon-close mr-1" />
            Close
          </t-button>

          <div class="relative swiper swiper-poi">
            <div class="swiper-wrapper">
              <div
                v-for="(slide, index) in hotspot.info"
                :key="index"
                class="swiper-slide"
              >
                <div
                  class="slide flex flex-col min-h-full overflow-hidden rounded bg-white"
                  style="height: 65vh;"
                >
                  <slide
                    :title="hotspot.title"
                    :slide="slide"
                  />

                  <div class="explore-button mt-auto pb-6 2xl:pb-8 text-center">
                    <t-button @click="onExplore">
                      Explore
                    </t-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="controls
                        flex items-center
                        justify-center gap-x-4 pt-4 2xl:pt-8 select-none">
              <t-button
                variant="circle"
                class="swiper-poi-prev"
              >
                <i class="icon-arrow-left" />
              </t-button>

              <span class="px-2 text-base font-semibold text-white">
                Swipe
              </span>

              <t-button
                variant="circle"
                class="swiper-poi-next"
              >
                <i class="icon-arrow-right" />
              </t-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper';
import Slide from './components/slide.vue';

export default {
  name: 'SolutionPopup',
  components: {
    Slide,
  },
  props: {
    hotspot: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    hide() {
      this.$emit('close');
    },
    onExplore() {
      this.$emit('explore');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper('.swiper-poi', {
        modules: [Navigation],
        speed: 600,
        loop: true,
        spaceBetween: 15,
        slidesPerView: 1,
        allowTouchMove: true,
        preventClicksPropagation: false,
        preventClicks: false,
        navigation: {
          nextEl: '.swiper-poi-next',
          prevEl: '.swiper-poi-prev',
        },
      });
    });
  },
  beforeDestroy() {
    this.swiper.destroy();
  },
};
</script>
