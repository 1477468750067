import { encode as toBase64 } from 'js-base64';

function getAuthHeader() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Basic ${toBase64(`${process.env.VUE_APP_API_LOGIN}:${process.env.VUE_APP_API_PASSWORD}`)}`,
  };
}

export default {
  getAuthHeader,
};
