const home = {
  index: 'home.index',
};

const showcase = {
  index: 'showcase.index',
};

const projects = {
  index: 'projects.index',
};

const myCollection = {
  index: 'my-collection.index',
};

const notFound = {
  index: 'notFound.index',
};

const factory = {
  index: 'factory.index',
  details: 'factory.details',
  demos: 'factory.details.demos',
  demoDetails: 'factory.details.demos.details',
  stages: 'factory.details.demos.details.stages',
};

const solutionDemo = {
  index: 'solution-demo.index',
};

export {
  home,
  showcase,
  projects,
  myCollection,
  notFound,
  factory,
  solutionDemo,
};
