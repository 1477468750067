import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import TButton from 'vue-tailwind/dist/t-button';
import TInput from 'vue-tailwind/dist/t-input';

import Meta from 'vue-meta';

import {
  required,
  email,
} from 'vee-validate/dist/rules';
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import * as customRules from '@/utils/validation-rules/index';

import { PdfPreview } from '@/components';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

const settings = {
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'border rounded cursor-pointer text-base font-medium transition-colors',
      classes: 'border-black px-6 py-3 bg-black text-white hover:border-orange-500 hover:bg-orange-600',
      variants: {
        secondary: 'border-orange-500 px-6 py-3 bg-orange-600 text-white hover:border-orange-500 hover:bg-orange-500 hover:text-white',
        highlight: 'border-orange-500 px-6 py-3 bg-orange-600 text-white hover:border-orange-500 hover:bg-orange-500 hover:text-white',
        circle: 'flex items-center justify-center w-12 h-12 rounded-full bg-black text-white',
        'circle-reversed': 'flex items-center justify-center w-12 h-12 rounded-full bg-black text-orange-500',
      },
    },
  },
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'appearance-none block rounded w-full px-4 py-3 2xl:py-4 border border-gray bg-white placeholder-gray-500 text-base 2xl:text-xl transition-colors',
      classes: 'text-black hover:border-black focus:outline-none focus:border-black',
    },
  },
};

Vue.use(VueTailwind, settings);

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

localize({
  en: {
    messages: {
      email: 'The {_field_} field must be a valid email',
      required: 'The {_field_} field is required',
    },
  },
});
localize('en');

extend('required', required);
extend('email', email);
extend(customRules.phone.name, {
  ...customRules.phone.schema,
});
extend(customRules.isChecked.name, {
  ...customRules.isChecked.schema,
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('PdfPreview', PdfPreview);

Vue.use(VueTailwind);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
