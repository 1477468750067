<template>
  <transition name="fade-in">
    <section
      v-if="showOverlay"
      class="idle-screen fixed z-150 left-0 top-0 w-full h-screen
             flex flex-col justify-center py-16 cursor-pointer bg-black-850"
      @click="hide"
    >
      <div class="relative z-10 container px-8">
        <div class="text-center">
          <div
            class="inline-flex w-24 2xl:w-32 h-24 2xl:h-32 p-2 bg-black mb-6"
          >
            <img
              :src="identityLogo"
              alt=""
              class="m-auto max-w-full max-h-full"
            />
          </div>

          <p class="text-base 2xl:text-lg font-semibold mb-6">
            Are you still there? The experience will <br>
            rest in {{ countDown }} seconds
          </p>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import poster from '@/assets/images/home/poster.jpg';
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';

const COUNT_START = +process.env.VUE_APP_COUNTDOWN_TIME;
const COUNT_END = -1;

export default {
  name: 'IdleScreen',
  data() {
    return {
      poster,
      showOverlay: false,
      time: null,
      inactiveTime: 5 * 60 * 1000,
      countDown: null,
      countDownTimer: null,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.screensaver,
      getters.content.identity,
    ]),
    identityLogo() {
      return this.identity.logo;
    },
  },
  watch: {
    showOverlay: {
      handler(newValue, oldValue) {
        if ((newValue !== oldValue) && newValue) {
          this.countDown = COUNT_START;
          this.countDownTimer = () => {
            if (this.countDown > COUNT_END) {
              setTimeout(() => {
                this.countDown -= 1;

                if (this.countDownTimer) {
                  this.countDownTimer();
                }
              }, 1000);
            }

            if (this.countDown === COUNT_END) {
              this.refreshSession();
              this.hide();
              this.$emit('on-finish');
            }
          };

          this.countDownTimer();
        }

        if ((newValue !== oldValue) && !newValue) {
          this.countDownTimer = null;
        }
      },
    },
  },
  mounted() {
    document.onmousemove = (e) => {
      if (!e.target.closest('.splash-screen') && !e.target.closest('.idle-screen')) {
        this.resetTimer();
      }
    };

    document.onkeypress = (e) => {
      if (!e.target.closest('.splash-screen') && !e.target.closest('.idle-screen')) {
        this.resetTimer();
      }
    };
  },
  methods: {
    ...mapActions([
      actions.navigation.showMyCollectionOverlay,
    ]),
    show() {
      this.showOverlay = true;
    },
    hide() {
      this.showOverlay = false;
    },
    resetTimer() {
      clearTimeout(this.time);
      this.time = setTimeout(this.show, this.inactiveTime);
    },
    refreshSession() {
      this[actions.navigation.showMyCollectionOverlay](false);

      this.$router.push({
        name: 'factory.index',
      }).catch(() => {});
    },
  },
};
</script>
