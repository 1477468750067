import { VueOfflineStorage } from 'vue-offline';

function getDefaultModel() {
  return {
    name: '',
    company: '',
    job: '',
    email: '',
    phone: '',
    checked: false,
  };
}

const mutations = {
  select: 'SELECT',
  setModelInput: 'SET_MODEL_INPUT',
  clearModel: 'CLEAR_MODEL',
  removeItem: 'REMOVE_ITEM',
  clearAll: 'CLEAR_ALL',
  setCurrentInputKeyboard: 'SET_CURRENT_KEYBOARD_INPUT',
};

const actions = {
  select: 'SELECT',
  setModelInput: 'SET_MODEL_INPUT',
  clearModel: 'CLEAR_MODEL',
  removeItem: 'REMOVE_ITEM',
  clearAll: 'CLEAR_ALL',
  setCurrentInputKeyboard: 'SET_CURRENT_KEYBOARD_INPUT',
};

const getters = {
  selected: 'selected',
  model: 'model',
  currentInputKeyboard: 'currentInputKeyboard',
};

let cacheKey;

const addToCache = async (data, experienceUid) => {
  cacheKey = `selected-${experienceUid}`;
  VueOfflineStorage.set(cacheKey, data);
};

const selectedCached = VueOfflineStorage.get(cacheKey);

const module = {
  state: {
    selected: selectedCached || [],
    model: getDefaultModel(),
    currentInputKeyboard: '',
  },
  mutations: {
    [mutations.select](state, product) {
      if (Array.isArray(product)) {
        const forAdd = [];

        product.forEach((p) => {
          const isExist = state.selected.find((sel) => sel.uid === p.uid);

          if (!isExist) {
            forAdd.push(p);
          }
        });

        state.selected.push(...forAdd);
      } else {
        state.selected.push(product);
      }
    },
    [mutations.removeItem](state, product) {
      if (Array.isArray(product)) {
        product.forEach((p) => {
          state.selected = state.selected.filter((sel) => sel.uid !== p.uid);
        });
      } else {
        state.selected = state.selected.filter((p) => p.uid !== product.uid);
      }
    },
    [mutations.clearAll](state) {
      state.selected = [];
    },
    [mutations.setModelInput](state, { inputName, inputValue }) {
      state.model[inputName] = inputValue;
    },
    [mutations.clearModel](state) {
      state.model = getDefaultModel();
    },
    [mutations.setCurrentInputKeyboard](state, inputName) {
      state.currentInputKeyboard = inputName;
    },
  },
  actions: {
    async [actions.select]({ commit, state, rootState }, product) {
      commit(mutations.select, product);
      await addToCache(state.selected, rootState.content.content.uid);
    },
    async [actions.removeItem]({ commit, state, rootState }, product) {
      commit(mutations.removeItem, product);
      await addToCache(state.selected, rootState.content.content.uid);
    },
    async [actions.clearAll]({ commit, state, rootState }) {
      commit(mutations.clearAll);
      await addToCache(state.selected, rootState.content.content.uid);
    },
    [actions.setModelInput]({ commit }, data) {
      commit(mutations.setModelInput, data);
    },
    [actions.clearModel]({ commit }) {
      commit(mutations.clearModel);
    },
    [actions.setCurrentInputKeyboard]({ commit }, inputName) {
      commit(mutations.setCurrentInputKeyboard, inputName);
    },
  },
  getters: {
    [getters.selected](state) {
      return state.selected;
    },
    [getters.model](state) {
      return state.model;
    },
    [getters.currentInputKeyboard](state) {
      return state.currentInputKeyboard;
    },
  },
};

export default {
  module,
  actions,
  getters,
};
