<template>
  <div
    v-if="product"
    class="relative rounded p-4 bg-black-full shadow-md text-white"
  >
    <t-button
      variant="circle"
      class="absolute z-10 top-4 right-4"
      @click="removeFromCollection"
    >
      <i class="icon-trash text-xl" />
    </t-button>

    <div class="flex items-center">
      <div class="flex rounded w-32 h-20 bg-orange-600 overflow-hidden">
        <img
          v-if="product.type === mediaTypes.image.key"
          :src="product.url"
          alt=""
          class="m-auto w-full h-full object-cover"
        />

        <video
          v-else-if="product.type === mediaTypes.video.key"
          :src="product.url"
          muted
          class="m-auto w-full h-full object-cover"
        />

        <div
          v-else
          class="w-full h-full overflow-hidden"
        >
          <pdf-preview :file-url="product.url" />
        </div>
      </div>

      <div class="w-9/12 pl-4 pr-12">
        <h3 class="text-lg 2xl:text-2xl font-normal">
          {{ product.title }}
        </h3>

        <h4 class="text-base 2xl:text-xl font-medium text-orange-500">
          {{ mediaTypes[product.type]?.name }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { actions } from '@/store';
import { mediaTypes } from '@/constants';

export default {
  name: 'CollectionCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mediaTypes,
    };
  },
  methods: {
    ...mapActions([
      actions.contactForm.removeItem,
    ]),
    removeFromCollection() {
      this[actions.contactForm.removeItem](this.product);
    },
  },
};
</script>
