import { notFound } from '@/constants/route-names';

const component = () => import('./not-found.view.vue');

export default [
  {
    path: 'not-found',
    alias: '*',
    name: notFound.index,
    component,
    meta: {
      title: '404',
    },
  },
];
