<template>
  <div v-if="isMyCollectionShown">
    <section
      class="fixed z-50 left-0 top-0
             w-full h-screen pt-12 pb-24 bg-black-850">
      <div class="container px-8">
        <h2 class="mb-16 text-2xl 2xl:text-5xl text-orange-500 font-bold">
          My Collection
        </h2>

        <div
          class="flex flex-col justify-center"
          style="height: calc(100vh - 20rem);"
        >
          <div class="flex">
            <div class="w-1/2">
              <template v-if="selected.length > 0">
                <div class="max-h-50vh pr-8 overflow-x-hidden
                            overflow-y-auto custom-scrollbar mb-8"
                >
                  <transition-group
                    name="list"
                    tag="ul"
                  >
                    <li
                      v-for="product in selected"
                      :key="product.uid"
                      class="mb-1"
                    >
                      <collection-card
                        :product="product"
                      />
                    </li>
                  </transition-group>
                </div>

                <t-button
                  v-if="selected.length > 1"
                  class="inline-flex items-center gap-x-3"
                  @click="clearAll"
                >
                  <i class="icon-trash" /> Clear All
              </t-button>
              </template>

              <div
                v-else
                class="flex flex-col justify-center items-center
                      h-50vh p-10 text-center"
              >
                <div
                  class="inline-flex w-24 2xl:w-32 h-24 2xl:h-32 p-2 bg-black mb-6"
                >
                  <img
                    :src="identityLogo"
                    alt=""
                    class="m-auto max-w-full max-h-full"
                  />
                </div>

                <p class="text-base 2xl:text-lg font-semibold mb-6">
                  Theres is nothing on your collection <br>
                  yet. You can add items, as you navigate <br>
                  through the experience.
                </p>

                <p>
                  <t-button
                    class="inline-flex items-center gap-x-2"
                    @click="back"
                  >
                    <i class="icon-arrow-back" /> Back
                  </t-button>
                </p>
              </div>
            </div>

            <div class="w-1/2 pl-8">
              <contact-form @on-confirm="onConfirm" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { actions, getters } from '@/store';

import CollectionCard from './components/collection-card.vue';
import ContactForm from './components/contact-form.vue';

export default {
  name: 'MyCollectionView',
  components: {
    CollectionCard,
    ContactForm,
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selected,
      getters.navigation.isMyCollectionShown,
      getters.content.identity,
    ]),
    identityLogo() {
      return this.identity.logo;
    },
  },
  methods: {
    ...mapActions([
      actions.navigation.showMyCollectionOverlay,
      actions.contactForm.clearAll,
    ]),
    show() {
      this[actions.navigation.showMyCollectionOverlay](true);
    },
    hide() {
      this[actions.navigation.showMyCollectionOverlay](false);
    },
    back() {
      this.hide();
    },
    clearAll() {
      this[actions.contactForm.clearAll]();
    },
    onConfirm(data) {
      this.hide();

      this.$emit('on-confirm', data);
    },
  },
};
</script>
