<template>
  <transition name="zoom-in-out">
    <div
      v-if="showOverlay"
      class="fixed z-110 top-0 left-0 w-full h-full py-24
             flex flex-col justify-center
           bg-black-850"
    >
      <div class="container px-8">
        <div class="text-center">
          <div
            class="inline-flex w-24 2xl:w-32 h-24 2xl:h-32 p-2 bg-black mb-6"
          >
            <img
              :src="identityLogo"
              alt=""
              class="m-auto max-w-full max-h-full"
            />
          </div>

          <p class="text-base 2xl:text-lg font-semibold mb-6">
            Sent! Your selected assets have been <br>
            delivered to <span class="text-orange-500">{{ email }}</span>
          </p>

          <t-button
            class="inline-flex gap-x-2"
            @click="hide"
          >
            <i class="icon-arrow-back" /> Back
          </t-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';

export default {
  name: 'ConfirmationOverlay',
  data() {
    return {
      showOverlay: false,
      email: '',
    };
  },
  computed: {
    ...mapGetters([
      getters.content.identity,
    ]),
    identityLogo() {
      return this.identity.logo;
    },
  },
  methods: {
    ...mapActions([
      actions.navigation.showMyCollectionOverlay,
    ]),
    show(data) {
      this.email = data.email;
      this.showOverlay = true;
    },
    hide() {
      this.showOverlay = false;
      this[actions.navigation.showMyCollectionOverlay](false);

      this.$router.push({
        name: 'factory.index',
      }).catch(() => {});
    },
  },
};
</script>
