<template>
  <div
    v-if="stage"
    class="interactive-card relative rounded bg-white text-black overflow-hidden"
  >
    <div class="interactive-card__row">
      <div
        class="p-4 2xl:p-6 interactive-card__text"
        :class="{ 'interactive-card__text--full' : !stage.media }"
      >
        <h3
          class="pl-10 mb-4 2xl:mb-6 text-lg 2xl:text-xl font-semibold leading-snug"
        >
          <span
            class="absolute left-4 2xl:left-6 inline-flex items-center justify-center
                   rounded-full w-8 h-8 bg-black text-white"
          >
            {{ index }}
          </span>

          <span class="line-clamp-2">
            {{ stage.title }}
          </span>
        </h3>

        <div class="border border-gray-100 mb-4" />

        <div
          v-html="stage.text"
          class="interactive-card__body pr-4 custom-scrollbar
                 text-block text-sm 2xl:text-base font-medium leading-normal"
        />
      </div>

      <div class="interactive-card__media">
        <template v-if="stage.media">
          <img
            v-if="stage.media.type === mediaTypes.image.key"
            :src="stage.media.url"
            :alt="stage.title"
            class="w-auto h-full object-cover"
          />

          <video
            v-else
            :src="stage.media.url"
            muted
            loop
            class="w-auto h-full object-cover object-left"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mediaTypes } from '@/constants';

export default {
  name: 'InteractiveCard',
  props: {
    stage: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mediaTypes,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/mixins';
@import '@/assets/scss/variables';

.interactive-card {
  &__row {
    display: flex;
    height: 54vh;
    transition: height 0.3s;

    .swiper-slide-active & {
      height: 58vh;
    }
  }

  &__text {
    flex: 0 0 25vw;
    width: 25vw;

    &--full {
      flex: 0 0 47vw;
      width: 47vw;

      .text-block {
        > p {
          display: inline-block;
          border-radius: 0.25rem;
          padding: 0.25rem 0.5rem;
          background-color: $color-brand;
          font-weight: 500;
          line-height: 2;
          color: $color-white;

          strong {
            font-weight: inherit;
          }
        }

        ul:not(.styled-list) {
          padding-left: 0;
          list-style: none;

          &:first-of-type {
            > li {
            position: relative;
            border-radius: 0.125rem;
            padding: 0.25rem 0.25rem 0.25rem 2rem;
            background-color: $color-gray-100;
            line-height: 2;

              &::before {
                content: '';
                position: absolute;
                left: 0.75rem;
                top: 50%;
                transform: translateY(-50%);
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: $color-brand;
              }
            }
          }

          &:last-of-type {
            display: flex;
            flex-wrap: wrap;
            gap: 0.6rem;

            > li {
              position: relative;
              flex: 0 0 calc(50% - 0.3rem);
              margin-bottom: 0;
              border-radius: 0.125rem;
              padding: 0.25rem 0.25rem 0.25rem 2.5rem;
              background-color: $color-gray-100;
              line-height: 2;

              &::before {
                content: '';
                position: absolute;
                left: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                background: url('@/assets/images/icons/checkmark.svg') no-repeat center / contain;
              }
            }
          }
        }
      }
    }
  }

  &__body {
    overflow: hidden;
    max-height: 39vh;

    .swiper-slide-active & {
      overflow: auto;
      max-height: 42vh;
    }
  }

  &__media {
    flex: 1 1 auto;
    max-width: 60vw;
    filter: grayscale(100%);

    .swiper-slide-active & {
      filter: none;
      transition: all 5s;
    }

    video,
    img {
      max-width: 100vw;
    }
  }
}
</style>
