<template>
  <transition-group
    v-if="breadcrumbs?.length > 0"
    name="breadcrumb"
    mode="out-in"
    tag="ul"
    class="flex ml-1 gap-x-1"
  >
    <li
      class="inline-block transition-all"
      v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs"
      :key="`${breadcrumbIndex}-${breadcrumb.text}`"
    >
      <t-button
        :disabled="breadcrumb.disabled"
        :title="breadcrumb.text"
        class="overflow-hidden whitespace-nowrap text-ellipsis
             hover:border-black hover:bg-black hover:text-orange-500"
        :class="{ 'pointer-events-none': breadcrumb.disabled || breadcrumb.active }"
        style="max-width: 17vw;"
        @click="navigateTo(breadcrumb)"
      >
        <span :class="{ ' text-orange-500': breadcrumb.active }">
          {{ breadcrumb.text }}
        </span>
      </t-button>
    </li>
  </transition-group>
</template>

<script>
import { mapActions } from 'vuex';
import { actions } from '@/store';

export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions([
      actions.navigation.showMyCollectionOverlay,
    ]),
    navigateTo(breadcrumb) {
      this[actions.navigation.showMyCollectionOverlay](false);

      this.$router.push(breadcrumb.to);
    },
  },
};
</script>
