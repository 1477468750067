<!-- eslint-disable max-len -->
<template>
  <transition @enter="animKeyboardEnter" @leave="animKeyboardLeave">
    <div
      v-show="currentInputKeyboard && isMyCollectionShown"
      ref="keyboard"
      id="keyboard"
      class="fixed p-1 pb-5 bg-black-opacity rounded-lg border-2 border-gray-500 z-100"
      v-on-click-away="close"
      style="bottom: 1rem; left: 50%; transform: translateX(-50%); min-width: 55rem;"
    >
      <div class="flex justify-between items-center py-2">
        <div
          ref="keyboardTrigger"
          id="keyboardTrigger"
          class="pl-7 py-2"
        >
          <i class="icon-drag" />
        </div>
        <div class="text-white">
          On-screen Keyboard
        </div>
        <div class="pr-7 pt-3" @click="close">
          <i class="icon-close" />
        </div>
      </div>
      <keyboard
        v-model="input"
        @customUS="customUS"
        @customUK="customUK"
        :layouts="[
          '1234567890-{⇤:backspace}|qwertyuiop[]|asdfghjkl;\'{↵:%0D%0A}|zxcvbnm,./{↑:goto:1}|`@{SPACE:space}{.com:customUS}{.co.uk:customUK}',
          '!@#$%^&*()_{⇤:backspace}|QWERTYUIOP[]|ASDFGHJKL:&quot;{↵:%0D%0A}|ZXCVBNM<>?{↑:goto:0}|±~{SPACE:space}{.com:customUS}{.co.uk:customUK}'
        ]"
      />
    </div>
  </transition>
</template>
<script>
import keyboard from 'vue-keyboard';
import { directive as onClickAway } from 'vue-clickaway';
import Draggable from 'gsap/Draggable';
import gsap from 'gsap';
import { mapGetters, mapActions } from 'vuex';
import { getters, actions } from '@/store';

gsap.registerPlugin(Draggable);
export default {
  name: 'Keyboard',
  directives: {
    onClickAway,
  },
  components: {
    keyboard,
  },
  computed: {
    ...mapGetters([
      getters.contactForm.currentInputKeyboard,
      getters.contactForm.model,
      getters.navigation.isMyCollectionShown,
    ]),
    input: {
      get() {
        return this[getters.contactForm.model][this[getters.contactForm.currentInputKeyboard]];
      },
      set(value) {
        this[actions.contactForm.setModelInput]({
          inputName: this[getters.contactForm.currentInputKeyboard],
          inputValue: value,
        });
      },
    },
  },
  methods: {
    ...mapActions([
      actions.contactForm.setModelInput,
      actions.contactForm.setCurrentInputKeyboard,
    ]),
    animKeyboardEnter(el, done) {
      const tl = gsap.timeline();
      tl.fromTo(
        this.$refs.keyboard,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'Power4.inOut',
          onComplete: done,
        },
      );
    },
    animKeyboardLeave(el, done) {
      const tl = gsap.timeline();
      tl.fromTo(
        this.$refs.keyboard,
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: 100,
          ease: 'Power4.inOut',
          onComplete: done,
        },
      );
    },
    close(e) {
      const isInputPhone = e.target.className === 'vti__input';
      if (isInputPhone) return;
      this[actions.contactForm.setCurrentInputKeyboard]('');
    },
    customUS() {
      this[actions.contactForm.setModelInput](
        {
          inputName: this[getters.contactForm.currentInputKeyboard],
          inputValue: `${this[getters.contactForm.model][this[getters.contactForm.currentInputKeyboard]]}.com`,
        },
      );
    },
    customUK() {
      this[actions.contactForm.setModelInput](
        {
          inputName: this[getters.contactForm.currentInputKeyboard],
          inputValue: `${this[getters.contactForm.model][this[getters.contactForm.currentInputKeyboard]]}.co.uk`,
        },
      );
    },
  },
  mounted() {
    Draggable.create('#keyboard', {
      bounds: 'body',
      type: 'x,y',
      trigger: '#keyboardTrigger',
    });
  },
};
</script>

<style lang="scss">
  .vue-keyboard-key {
    color: #333;
    font-weight: 600;
    padding: .75rem 1.25rem;
    min-width: 4rem;
    min-height: 3rem;

    &[data-text="↑"],
    &[data-text=".com"],
    &[data-text=".co.uk"] {
      width: 8.25rem;
    }
    &[data-text="SPACE"] {
      width: 25.25rem;
    }
  }
</style>
