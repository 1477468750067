const getUrl = (url) => (process.env.VUE_APP_BASE_URL === '/'
  ? `${window.location.origin}/${url}`
  : `${window.location.origin}${process.env.VUE_APP_BASE_URL}/${url}`);

export default {
  setupManifest(experienceId) {
    const manifest = {
      name: 'DMI Factory Tour Experience',
      short_name: 'DMI Experience',
      theme_color: '#333333',
      icons: [
        {
          src: getUrl('img/icons/favicon-32x32.png'),
          sizes: '32x32',
          type: 'image/png',
        },
        {
          src: getUrl('img/icons/favicon-16x16.png'),
          sizes: '16x16',
          type: 'image/png',
        },
        {
          src: getUrl('img/icons/192.png'),
          type: 'image/png',
          sizes: '192x192',
        },
        {
          src: getUrl('img/icons/384.png'),
          type: 'image/png',
          sizes: '384x384',
        },
        {
          src: getUrl('img/icons/512.png'),
          type: 'image/png',
          sizes: '512x512',
        },
        {
          src: getUrl('img/icons/1024.png'),
          type: 'image/png',
          sizes: '1024x1024',
        },
        {
          src: getUrl('img/icons/apple-touch-icon.png'),
          sizes: '512x512',
          type: 'image/png',
        },
      ],
      start_url: getUrl(experienceId),
      display: 'standalone',
      background_color: '#333333',
      orientation: 'landscape',
    };

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
  },
};
