<template>
  <section
    v-if="demo"
    class="fixed z-50 left-0 top-0 w-full h-screen pt-8 2xl:pt-5vh pb-24 bg-black-850
           text-white"
  >
    <div class="container px-8">
      <h2
        class="max-w-3/4 text-3xl 2xl:text-5xl
               font-bold leading-snug mb-4 2xl:mb-8"
      >
        {{ demo.title }}
      </h2>
    </div>

    <div class="container pr-8">
      <div
        class="swiper swiper-stages transition-opacity"
        :class="{ 'opacity-0' : !showSlider }"
      >
        <div class="swiper-wrapper items-center pb-6">
          <div
            v-for="(slide, slideIndex) in demo.slides"
            :key="slide.id"
            class="swiper-slide p-4"
          >
            <interactive-card
              :stage="slide"
              :index="slideIndex + 1"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';
import { mapGetters } from 'vuex';
import { getters } from '@/store';
import InteractiveCard from './components/interactive-card.vue';

export default {
  name: 'StagesView',
  components: {
    InteractiveCard,
  },
  data() {
    return {
      swiperStages: null,
      demoUid: this.$route.params.demoUid,
      demo: null,
      showSlider: false,
    };
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selected,
      getters.content.solutions,
    ]),
    selectedCount() {
      return this.selected.length;
    },
  },
  mounted() {
    this.getData();

    setTimeout(() => {
      this.swiperStages = new Swiper('.swiper-stages', {
        speed: 600,
        slideToClickedSlide: true,
        slidesPerView: 'auto',
        initialSlide: 0,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        on: {
          transitionStart(swiper) {
            const prevSlide = swiper.slides[swiper.previousIndex];
            const prevSlideVideo = prevSlide?.querySelector('video');

            if (prevSlideVideo) {
              prevSlideVideo.pause();
            }
          },
          transitionEnd(swiper) {
            const activeSlide = swiper.slides[swiper.activeIndex];
            const activeSlideVideo = activeSlide?.querySelector('video');

            if (activeSlideVideo) {
              activeSlideVideo.play();
            }
          },
          reachEnd() {
            this.snapGrid = [...this.slidesGrid];

            setTimeout(() => {
              this.slideNext();
              clearTimeout();
            }, 1);
          },
        },
      });
    }, 400);

    setTimeout(() => {
      this.showSlider = true;
    }, 550);
  },
  methods: {
    getData() {
      if (!this.demoUid) return;
      const demos = this.solutions.reduce((acc, solution) => [
        ...acc,
        ...solution.demos,
      ], []);

      const data = demos
        .find((d) => d.uid === this.demoUid);
      this.demo = data;
    },
  },
};
</script>

<style lang="scss">
.swiper-stages {
  width: 100%;

  .swiper-wrapper {
    height: 70vh;
  }

  .swiper-slide {
    display: inline-block;
    width: auto;
  }
}
</style>
