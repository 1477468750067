<template>
  <div>
    <footer class="fixed z-100 left-0 bottom-0 w-full px-4 xl:px-8 pt-4 pb-4 xl:pb-8">
      <div class="flex items-end justify-between gap-x-4">
        <div class="flex items-end">
          <div class="flex flex-col">
            <div class="relative">
              <button
                class="absolute flex w-full h-16 2xl:h-24
                       border rounded border-black px-6 py-3 bg-black"
                style="transform: translateY(calc(-100% - 0.25rem))"
                @click="showSplashScreen"
              >
                <img
                  :src="identityLogo"
                  alt=""
                  class="m-auto max-w-full max-h-full"
                />
              </button>
            </div>

            <t-button @click="toHome">
              <i class="icon-home mr-2" /> Home
            </t-button>
          </div>

          <breadcrumbs :breadcrumbs="breadcrumbs" />
        </div>

        <div class="relative">
          <span
            v-if="selectedCount"
            class="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2
                    rounded-full py-1 px-2 bg-white text-sm text-black font-medium leading-none"
          >
            {{ selectedCount }}
          </span>

          <t-button
            v-if="!isMyCollectionShown"
            class="inline-flex items-center gap-x-3"
            @click="showMyCollection"
          >
            My Collection
            <img
              src="@/assets/images/icons/icon-copy-02.svg"
              alt=""
              width="14"
              height="14"
            />
          </t-button>

          <t-button
            v-else
            class="hover:border-black hover:bg-black"
            @click="hideMyCollection"
          >
            <span class="inline-flex items-center gap-x-3 text-orange-500">
              My Collection
              <img
                src="@/assets/images/icons/icon-copy-02.svg"
                alt=""
                width="14"
                height="14"
              />
            </span>
          </t-button>
        </div>
      </div>
    </footer>

    <transition name="collection-fade-in">
      <my-collection
        ref="myCollectionOverlay"
        @on-confirm="showConfirmationOverlay"
      />
    </transition>

    <confirmation-overlay ref="confirmationOverlay" />

    <splash-screen ref="splashScreen" />

    <idle-screen
      ref="idleScreen"
      @on-finish="onIdleOverlayFinish"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getters, actions } from '@/store';
import {
  Breadcrumbs,
  MyCollection,
  SplashScreen,
  IdleScreen,
} from '@/components';
import ConfirmationOverlay from '@/components/my-collection/components/confirmation-overlay.vue';

export default {
  name: 'FooterLayout',
  components: {
    Breadcrumbs,
    MyCollection,
    SplashScreen,
    IdleScreen,
    ConfirmationOverlay,
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selected,
      getters.navigation.isMyCollectionShown,
      getters.content.identity,
    ]),
    selectedCount() {
      return this.selected.length;
    },
    breadcrumbs() {
      if (typeof this.$route.meta.breadcrumbs === 'function') {
        return this.$route.meta.breadcrumbs.call(this, this.$route);
      }
      return this.$route.meta.breadcrumbs;
    },
    identityLogo() {
      return this.identity.logo;
    },
  },
  methods: {
    ...mapActions([
      actions.navigation.showMyCollectionOverlay,
    ]),
    showMyCollection() {
      this.$refs.myCollectionOverlay.show();
    },
    hideMyCollection() {
      this.$refs.myCollectionOverlay.hide();
    },
    toHome() {
      this[actions.navigation.showMyCollectionOverlay](false);

      this.$router.push({
        name: 'factory.index',
      }).catch(() => {});
    },
    showSplashScreen() {
      this.$refs.splashScreen.show();
    },
    onIdleOverlayFinish() {
      this.showSplashScreen();
    },
    showConfirmationOverlay(data) {
      this.$refs.confirmationOverlay.show(data);
    },
  },
};
</script>
