import { factory, showcase } from '@/constants/route-names';
import { Route } from 'vue-router';
import store from '@/store';

const component = () => import('./factory.view.vue');

function getSolutionTitle(solutionUid) {
  // eslint-disable-next-line import/no-named-as-default-member
  const solutionTitle = store.getters.solutions.find((s) => s.uid === solutionUid)?.title;
  return solutionTitle;
}

function getSolutionDemoTitle(solutionUid, demoUid) {
  // eslint-disable-next-line import/no-named-as-default-member
  const solutionDemos = store.getters.solutions.find((s) => s.uid === solutionUid).demos;
  const demoTitle = solutionDemos.find((d) => d.uid === demoUid)?.title;
  return demoTitle;
}

export default [
  {
    path: '',
    name: factory.index,
    component,
    meta: {
      title: 'Factory',
      breadcrumbs: [
        {
          text: 'Showcase',
          to: {
            name: showcase.index,
          },
        },
      ],
    },
  },
  {
    path: 'factory/:solutionUid',
    name: factory.details,
    component,
    meta: {
      title: 'Factory',
      breadcrumbs(route) {
        // eslint-disable-next-line no-param-reassign
        if (!route) route = Route;

        const { solutionUid } = route.params;

        return [
          {
            text: getSolutionTitle(solutionUid),
            active: true,
          },
        ];
      },
    },
  },
  {
    path: 'factory/:solutionUid/demos',
    name: factory.demos,
    component,
    meta: {
      title: 'Factory',
      breadcrumbs(route) {
        // eslint-disable-next-line no-param-reassign
        if (!route) route = Route;

        const { solutionUid } = route.params;

        return [
          {
            text: getSolutionTitle(solutionUid),
            to: {
              name: factory.details,
            },
          },
          {
            text: 'Demonstrations',
            active: true,
          },
        ];
      },
    },
  },
  {
    path: 'factory/:solutionUid/demos/:demoUid',
    name: factory.demoDetails,
    component,
    meta: {
      title: 'Factory',
      breadcrumbs(route) {
        // eslint-disable-next-line no-param-reassign
        if (!route) route = Route;

        const { solutionUid, demoUid } = route.params;

        return [
          {
            text: getSolutionTitle(solutionUid),
            to: {
              name: factory.details,
            },
          },
          {
            text: 'Demonstrations',
            to: {
              name: factory.demos,
            },
          },
          {
            text: getSolutionDemoTitle(solutionUid, demoUid),
            active: true,
          },
        ];
      },
    },
  },
  {
    path: 'factory/:solutionUid/demos/:demoUid/stages',
    name: factory.stages,
    component,
    meta: {
      title: 'Factory',
      // eslint-disable-next-line
      breadcrumbs(route) {
        // eslint-disable-next-line no-param-reassign
        if (!route) route = Route;

        const { solutionUid, demoUid } = route.params;

        return [
          {
            text: getSolutionTitle(solutionUid),
            to: {
              name: factory.details,
            },
          },
          {
            text: 'Demonstrations',
            to: {
              name: factory.demos,
            },
          },
          {
            text: getSolutionDemoTitle(solutionUid, demoUid),
            to: {
              name: factory.demoDetails,
            },
          },
          {
            text: 'Stages',
            active: true,
          },
        ];
      },
    },
  },
];
